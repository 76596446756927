.orange-bar {
  background: linear-gradient(rgba(255,79,15, 0.90), rgba(255,79,15, 0.90)), url("../images/gradient/hexagons.svg");
  background-size: cover;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center -600px;
  filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.25));
  gap: 2rem;
  width: 100%;
}