.fill-line {
    height: 0px;
    flex: 1;
    width: 100%;
    border: 1px dotted lightgrey;
}

.fill-line.margin-right {
    margin-right: 10px;
}

.fill-line.margin-left {
    margin-left: 10px;
}

.fill-line.line-style-solid {
    border: 1px solid lightgrey;
}

.fill-line.line-style-none {
    border: 0px dotted lightgrey;
}

.spacer {
    margin-left: 20px;
}

.gap-2 {
    gap: 2px;
}

.gap-10 {
    gap: 10px;
}

.vh-25 {
    height: 25vh;
}

.vh-30 {
    height: 30vh;
}

.vh-50 {
    height: 50vh;
}

.vh-60 {
    height: 60vh;
}

.vh-75 {
    height: 75vh;
}

.vh-100 {
    height: 100vh;
}

.mh-100 {
    min-height: 100px;
}

.padding-10 {
    padding: 10px;
}

.w-10 {
    width: 10%;
}

.w-60 {
    width: 60%;
}

.w-80 {
    width: 80%;
}